// Hooks
import { useCallback, useEffect, useState } from 'react'
import useLocalize from '../../../../../locales/hook'
import { useContactInformations } from '../../../../../redux/sealing/hooks'
//import { useProfile } from '../../../../../redux/profile/hooks'

// Components
import { IonModal } from '@ionic/react'
import Icon from '../../../../../atoms/Icon/Icon'
import Button from '../../../../../atoms/Button/Button'
import TextInput from '../../../../../atoms/TextInput/TextInput'
import PhoneInput from '../../../../../atoms/PhoneInput/PhoneInput'

// Styles & Utils
import './CellMemberContactInformationModal.scss'
import IconTypes from '../../../../../utils/types/IconTypes'
import isEmailValid from '../../../../../utils/booleans/isEmailValid'

const CellMemberContactInformationModal: React.FC = () => {
  // Hooks
  const t = useLocalize()
  const {
    sendContactInformations,
    shouldSendInformations,
    shouldSendInformationsPhoneError,
    setShouldSendInformations,
    shouldSendInformationsEmailError,
    setShouldSendInformationsEmailError,
    setShouldSendInformationsPhoneError,
    pEmail,
    pPhoneNumber,
    pPrefix,
  } = useContactInformations()

  // States
  const [modalOpen, setModalOpen] = useState(shouldSendInformations)
  const [personalPhonePrefix, setPrefix] = useState(pPrefix || '')
  const [personalEmail, setEmail] = useState(pEmail || '')
  const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [emailMessageError, setEmailMessageError] = useState('')
  const [personalPhoneNumber, setPhoneNumber] = useState(pPhoneNumber || '')

  useEffect(() => {
    setModalOpen(shouldSendInformations)
  }, [shouldSendInformations])

  useEffect(() => {
    if (shouldSendInformationsEmailError) {
      setEmailMessageError(t('this_email_address_is_already_in_use'))
      setEmailError(true)
      setModalOpen(true) // Garde le modal ouvert en cas d'erreur d'email
    }
  }, [shouldSendInformationsEmailError])

  useEffect(() => {
    if (shouldSendInformationsPhoneError) {
      setPhoneError(true)
      setModalOpen(true) // Garde le modal ouvert en cas d'erreur de téléphone
    }
  }, [shouldSendInformationsPhoneError])

  // Effet pour reset les erreurs si l'email est modifié
  useEffect(() => {
    if (personalEmail) {
      setEmailError(false)
      setEmailMessageError('')
    }
  }, [personalEmail])

  // fonction pour reset les erreurs
  const resetErrors = useCallback(() => {
    setEmailError(false)
    setPhoneError(false)
    setEmailMessageError('')
    setShouldSendInformationsEmailError(false)
    setShouldSendInformationsPhoneError(false)
  }, [setShouldSendInformationsEmailError, setShouldSendInformationsPhoneError])

  // Function to handle validation
  const handleValidate = useCallback(() => {
    let hasError = false

    // Validation du numéro de téléphone
    if (personalPhoneNumber && personalPhoneNumber.length < 9) {
      setPhoneError(true)
      hasError = true
    } else {
      setPhoneError(false)
    }

    // Validation de l'email
    if (personalEmail && !isEmailValid(personalEmail)) {
      setEmailError(true)
      hasError = true
    } else {
      setEmailError(false)
    }

    // Si les deux champs sont vides
    if (!personalEmail && !personalPhoneNumber) {
      setEmailError(true)
      setPhoneError(true)
      hasError = true
    }

    // Si une erreur est détectée, ne pas fermer le modal
    if (hasError) {
      return
    }

    // Reset des messages d'erreur si tout est correct
    setEmailMessageError('')
    sendContactInformations({ personalPhonePrefix, personalPhoneNumber, personalEmail })
    setPhoneNumber('')
    setPhoneError(false)
    setEmailError(false)
    setModalOpen(false) // Ferme le modal après validation réussie
    setShouldSendInformations(false)
  }, [
    personalPhonePrefix,
    personalPhoneNumber,
    personalEmail,
    sendContactInformations,
    setShouldSendInformations,
  ])

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
    setShouldSendInformations(false)
    resetErrors()
  }, [modalOpen])

  const handleOnChangePhoneNumber = useCallback((prefix, phoneNumber) => {
    setPrefix(prefix)
    setPhoneNumber(phoneNumber)
  }, [])

  return (
    <IonModal className='CellMemberContactInformationModal' isOpen={modalOpen}>
      <Icon className='close' icon={IconTypes.X} onClick={handleCloseModal} />{' '}
      {/* Ferme le modal */}
      <div className='title'>{t('modal_contact_information_title')}</div>
      <div className='box'>
        <div className='description'>{t('modal_contact_information_description')}</div>
        <PhoneInput
          label={t('phoneNumber')}
          className='textInputBox'
          valueKey='phone'
          error={shouldSendInformationsPhoneError || phoneError}
          initValue={personalPhoneNumber || ''}
          onChange={handleOnChangePhoneNumber}
        />
        {shouldSendInformationsPhoneError && (
          <div className='phoneInputBox isError'>
            <div className='label'>{t('phone_number_already_existing')}</div>
          </div>
        )}

        {phoneError && (
          <div className='phoneInputBox isError'>
            <div className='label'>{t('check_phone_number')}</div>
          </div>
        )}

        <TextInput
          type='email'
          label={t('email')}
          className='textInputBox'
          valueKey='email'
          error={emailError}
          errorMessage={emailMessageError}
          prioValue={personalEmail}
          maxLength={80}
          onChange={setEmail}
          placeholder={t('email_assistance_placeholder')}
        />

        <div className='buttonBox'>
          <Button fill label={t('validate')} onClick={handleValidate} />
        </div>
      </div>
    </IonModal>
  )
}

export default CellMemberContactInformationModal
