import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestStatus } from '../appConfig/services'
import {
  deleteAxios,
  getAxios,
  getTokenHeaders,
  patchAxios,
  setAxios,
} from '../../utils/lib/requestAxios'
import { setAppLoading, setErrorStatus } from '../app/redux'
import jsonToString from '../../utils/lib/jsonToString'
import { setGeneratingKeys } from './redux'
import { requestAnimators } from '../animators/services'
import { CIVILITY_DISPLAY, SEX_DISPLAY } from '../../locales/constants'

// For initApp
export const requestInitSealingStatus = createAsyncThunk<unknown, { isAdmin: boolean }>(
  'sealing/requestInitSealingStatus',
  async ({ isAdmin }, { dispatch }) => {
    dispatch(requestGetSealing())
    if (isAdmin) {
      dispatch(requestCellMembers({}))
      dispatch(requestAnimators())
    }
  }
)

/**     CELL MEMBERS     **/
// Potential Cell members
export const requestUsers = createAsyncThunk<any, any>(
  'sealing/requestUsers',
  async ({ search, params = {} }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `/users/electors?${jsonToString({ search, ...params })}`,
      getTokenHeaders(true)
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestUsersStates = (builder) => {
  builder.addCase(requestUsers.fulfilled, (state, { payload }) => {
    const { data } = payload

    state.isLoading = false
    const { users = [] } = data || {}

    state.userIds = []
    state.potentialUserCell = []
    state.potentialUserIds = []
    users.forEach((user) => {
      const { id: userId } = user

      if (!state.potentialUserIds.includes(userId)) {
        state.potentialUserCell.push(user)
        state.potentialUserIds.push(userId)
      }
    })
  })
}

// Request Cell members
export const requestCellMembers = createAsyncThunk<any, any>(
  'sealing/requestCellMembers',
  async ({ search = '', params = {} }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `/seal-cell-members?${jsonToString({ search, ...params })}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return response.data
  }
)

export const requestCellMembersStates = (builder) => {
  builder.addCase(requestCellMembers.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestCellMembers.fulfilled, (state, { payload: data }) => {
    state.isLoading = false
    state.userIds = []
    state.userIdsCellMemberSelection = []
    state.cellMembersIds = data.map((item) => {
      state.cellMembersById[item.id] = item
      state.userIds.push(item.userId)
      state.userIdsCellMemberSelection.push(item.userId)
      return item.id
    })
  })

  builder.addCase(requestCellMembers.rejected, (state) => {
    state.isLoading = false
  })
}

// Add or Delete Cell member
export const requestChangeCellMembers = createAsyncThunk<any, { cellId?: number; userId?: string }>(
  'sealing/requestChangeCellMembers',
  async ({ userId, cellId }, { dispatch, rejectWithValue }) => {
    let response: any

    if (cellId && !userId) {
      response = await deleteAxios(`/seal-cell-members/${cellId}`, getTokenHeaders())
    } else if (cellId && userId) {
      response = await setAxios(`/seal-cell-members/${cellId}/user`, { userId }, getTokenHeaders())
    } else {
      response = await setAxios('/seal-cell-members', { userId }, getTokenHeaders())
    }
    dispatch(requestStatus())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    dispatch(requestCellMembers({}))
    return {
      userId,
      cellId,
      data: response.data,
    }
  }
)

export const requestChangeCellMembersStates = (builder) => {
  builder.addCase(
    requestChangeCellMembers.fulfilled,
    (state, { payload: { data, cellId, userId } }) => {
      state.isLoading = false
      if (cellId && !userId) {
        state.cellMembersIds = state.cellMembersIds.filter((id) => id !== cellId)
        delete state.cellMembersById[cellId]
      } else if (cellId && userId) {
        state.cellMembersById[data.id] = data
      } else {
        if (!state.cellMembersIds.includes(data.id)) {
          state.cellMembersIds.push(data.id)
        }
        state.cellMembersById[data.id] = data
      }
    }
  )
}

// Random CellMember User
export const requestRandomCellMember = createAsyncThunk<any, any>(
  'users/requestRandomCellMember',
  async ({ filters = {}, clear = false }) => {
    const response: any = await getAxios(
      `/seal-cell-members/random?${jsonToString({ ...filters })}`,
      getTokenHeaders(true),
      false
    )

    if (response.error) {
      return {
        clear,
        data: {},
      }
    } else {
      return {
        clear,
        data: response.data || {},
      }
    }
  }
)

export const requestRandomCellMemberStates = (builder) => {
  builder.addCase(requestRandomCellMember.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    requestRandomCellMember.fulfilled,
    (state, { payload: { data: user, clear } }) => {
      const { id: userId } = user
      state.isLoading = false

      if (userId) {
        if (clear || !state.userIdsCellMemberSelection?.length) {
          state.userIdsCellMemberSelection = [userId]
        } else if (!state.userIdsCellMemberSelection.includes(userId)) {
          state.userIdsCellMemberSelection = [userId, ...state.userIdsCellMemberSelection]
        }
        state.userById[userId] = {
          userId,
          username: user?.login,
          pic: user?.avatar,
          ...user,
          college: { type: `college_${user?.college?.type}` },
          sex: SEX_DISPLAY[user?.sex],
          civility: CIVILITY_DISPLAY[user?.civility],
        }
      } else if (clear) {
        state.userIdsCellMemberSelection = []
      }
    }
  )

  builder.addCase(requestRandomCellMember.rejected, (state) => {
    state.isLoading = false
  })
}

/**     SEALING      **/
// Request Get Sealing Status
export const requestGetSealing = createAsyncThunk<undefined, undefined>(
  'appConfig/requestGetSealing',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/status', getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data.PLATFORM_SEALED
    }
  }
)

export const requestGetSealingState = (builder) => {
  builder.addCase(requestGetSealing.fulfilled, (state, { payload: isSealed }) => {
    state.isSealed = isSealed
  })
}

// Request Generate Keys
export const requestGenerateKeys = createAsyncThunk<any, undefined>(
  'sealing/requestGenerateKeys',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setGeneratingKeys(true))
    const response: any = await setAxios('/encryption/generate-all', {}, getTokenHeaders())
    dispatch(requestStatus())
    dispatch(setGeneratingKeys(false))
    if (response.error) {
      dispatch(
        setErrorStatus({
          message: response.error.response.data.templateKey,
          messageParams: response.error.response.data.params,
        })
      )
      return rejectWithValue(response.error.response.data)
    }
    return response.data
  }
)

export const requestGenerateKeysState = (builder) => {
  builder.addCase(requestGenerateKeys.pending, (state) => {
    state.errorKeys = null
  })
  builder.addCase(requestGenerateKeys.fulfilled, (state, { payload }) => {
    state.errorKeys = null
    state.keys = payload
  })
  builder.addCase(requestGenerateKeys.rejected, (state, { payload }) => {
    state.errorKeys = payload.message || null
  })
}

// Request Set Sealing
export const requestSetSealing = createAsyncThunk<any, any>(
  'sealing/requestSetSealing',
  async (isSealed, { dispatch, rejectWithValue }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios(
      isSealed ? '/platform/unseal' : '/platform/seal',
      {},
      getTokenHeaders()
    )
    dispatch(requestStatus())
    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(
        setErrorStatus({
          message: response.error.response.data.message,
        })
      )
      return rejectWithValue(response.error.response.status)
    }
    return !isSealed
  }
)

// Request Set Unsealing and Genetate Minute
export const requestSetUnsealing = createAsyncThunk<any, any>(
  'sealing/requestSetUnsealing',
  async (isSealed, { dispatch, rejectWithValue }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios('/platform/generateMinute', {}, getTokenHeaders())
    dispatch(requestStatus())
    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(
        setErrorStatus({
          message: response.error.response.data.message,
        })
      )
      return rejectWithValue(response.error.response.status)
    }
    return !isSealed
  }
)

export const requestSetSealingState = (builder) => {
  builder.addCase(requestSetSealing.fulfilled, (state, { payload }) => {
    state.isSealed = payload
  })
}

// Request Set Unsealing and Genetate Minute
export const requestSetUnsealingWithPrivateKey = createAsyncThunk<any, any>(
  'sealing/requestSetUnsealingWithPrivateKey',
  async ({ isSealed, archivePrivateKey }, { dispatch, rejectWithValue }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios(
      '/platform/generateMinuteWithPrivateKey',
      { archivePrivateKey },
      getTokenHeaders()
    )
    dispatch(requestStatus())
    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(
        setErrorStatus({
          message: response.error.response.data.message,
        })
      )
      return rejectWithValue(response.error.response.status)
    }
    return !isSealed
  }
)

export const requestSetUnsealingWithPrivateKeyState = (builder) => {
  builder.addCase(requestSetUnsealingWithPrivateKey.fulfilled, (state, { payload }) => {
    state.isSealed = payload
  })
}

// Request Me Sealing
export const requestMeSealing = createAsyncThunk<any, undefined>(
  'sealing/requestMeSealing',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/seal-cell-members/me', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestMeSealingState = (builder) => {
  builder.addCase(requestMeSealing.fulfilled, (state, { payload }) => {
    state.me = payload
  })
}

// Request Confirm Password
export const requestConfirmPassword = createAsyncThunk<any, { password: string }>(
  'sealing/requestConfirmPassword',
  async ({ password }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios(
      '/encryption/confirm-password',
      { password },
      getTokenHeaders()
    )

    dispatch(setAppLoading(false))
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestConfirmPasswordState = (builder) => {
  builder.addCase(requestConfirmPassword.pending, (state) => {
    state.me.error = false
    state.waitingSeal = true
  })
  builder.addCase(requestConfirmPassword.fulfilled, (state) => {
    state.me.error = false
    state.me.hasConfirmedPassword = true
  })
  builder.addCase(requestConfirmPassword.rejected, (state) => {
    state.me.error = true
    state.waitingSeal = false
  })
}

// Request Me Unseal
export const requestMeUnseal = createAsyncThunk<any, { password: string }>(
  'sealing/requestMeUnseal',
  async ({ password }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios('/encryption/unlock', { password }, getTokenHeaders())

    dispatch(setAppLoading(false))
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestMeUnsealState = (builder) => {
  builder.addCase(requestMeUnseal.pending, (state) => {
    state.waitingUnseal = true

    if (!state.me) state.me = {}
    state.me.error = false
  })
  builder.addCase(requestMeUnseal.fulfilled, (state, { payload }) => {
    state.me = { ...state.me, ...payload, hasUnlockedPlatform: true }
    state.shouldUnseal = false
  })
  builder.addCase(requestMeUnseal.rejected, (state) => {
    state.waitingUnseal = false

    if (!state.me) state.me = {}
    state.me.error = true
  })
}

export const requestSendContactInformations = createAsyncThunk<
  any,
  { personalPhonePrefix?: string; personalPhoneNumber?: string; personalEmail?: string }
>(
  'sealing/requestSendContactInformations',
  async (
    { personalPhonePrefix, personalPhoneNumber, personalEmail },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setAppLoading(true))
    const response: any = await patchAxios(
      '/seal-cell-members/me',
      {
        personalPhoneNumber: personalPhoneNumber?.length ? personalPhoneNumber : null,
        personalEmail: personalEmail?.length ? personalEmail : null,
        personalPhonePrefix:
          personalPhoneNumber?.length && personalPhonePrefix?.length ? personalPhonePrefix : null,
      },
      getTokenHeaders()
    )
    dispatch(setAppLoading(false))
    if (response.error) {
      return rejectWithValue(response.error.response.data)
    } else {
      return response.data
    }
  }
)

export const requestSendContactInformationsState = (builder) => {
  builder.addCase(requestSendContactInformations.fulfilled, (state, { payload }) => {
    state.me = { ...state.me, ...payload }
    state.shouldSendInformations = false
    state.shouldSendInformationsPhoneError = false
    state.shouldSendInformationsEmailError = false
  })
  builder.addCase(requestSendContactInformations.rejected, (state, { payload }) => {
    let message = payload.message
    message.includes('Email')
      ? (state.shouldSendInformationsEmailError = true)
      : (state.shouldSendInformationsEmailError = false)
    message.includes('Phone')
      ? (state.shouldSendInformationsPhoneError = true)
      : (state.shouldSendInformationsPhoneError = false)
  })
}
